.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  max-width: 100%;
}

body {
  color: #0b64b3;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.hero-heading-left {
  background-color: #f5f7fa;
  background-image: linear-gradient(115deg, #e56a42 36%, #96935d 71%, #96935d);
  border-bottom: 1px solid #e4ebf3;
  padding: 80px 30px;
  position: relative;
}

.container {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.hero-wrapper {
  justify-content: space-between;
  align-items: bottom;
  display: flex;
}

.hero-split {
  max-width: 46%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.margin-bottom-24px {
  color: #f2e1d3;
  margin-bottom: 0;
  font-family: Gotham XLight, sans-serif;
  font-size: 17px;
}

.button-primary {
  height: 45px;
  color: #353654;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #b9fca8;
  border: 1.5px solid #0b64b3;
  border-radius: 10px;
  align-self: center;
  padding: 12px 25px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
  display: none;
}

.button-primary:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary:active {
  background-color: #43464d;
}

.button-primary.footer-form-button {
  padding: 8px 18px;
  position: absolute;
  right: 8px;
}

.button-primary.log-in-button {
  width: 220px;
  color: #f2e1d3;
  text-align: center;
  background-color: #96935d;
  border-color: #f2e1d3;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Gotham Medium, sans-serif;
  display: block;
}

.button-primary.log-in-eth-button {
  display: none;
}

.light-text-field {
  width: 460px;
  padding: 10px;
  margin-top: 20px;
  border: none;
  color: #96935d;
  background-color: #f2e1d3; 
  opacity: 0.6;
  font-family: Gotham Medium, sans-serif;
  border-radius: 10px;
  font-size: 16px; /* Custom font size */
}

.shadow-two {
  width: 100%;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
  box-shadow: 0 4px 24px rgba(150, 163, 181, .08);
}

.popup {
  z-index: 100;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F5E1D1;
  padding: 20px;
  border: 1px solid #969455;
  width: 80%;
  max-width: 500px; /* Optional: for better control over popup width */
  font-family: Gotham Medium, sans-serif;
  font-size: 20px;
  color: hsl(58, 28%, 46%);
}

.close {
  color: #969455;
  float: right;
  font-size: 28px;
  font-weight: bold;
  font-family: Gotham Medium, sans-serif;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

popup-content button {
  font-family: Gotham Medium, sans-serif;
  font-size: 16px;
  padding: 20px 20px;
  margin: 10px;
  margin-top: 40px;
  border: 1px #969455;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup-content button.close-action {
  background-color: #969455;
  color: #F5E1D1;
}

.popup-content button.close-action:hover {
  background-color: #969455; 
}

.popup-content button.popup-action {
  background-color: #969455;
  color: #F5E1D1;
}

.popup-content button.popup-action:hover {
  background-color: #969455; 
}


.navbar-logo-left {
  height: auto;
  color: #353654;
  background-color: #96935d;
  display: block;
  position: static;
}

.navbar-logo-left-container {
  z-index: 5;
  width: 1030px;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
  margin-left: auto;
  margin-right: auto;
  padding: 15px 20px;
}

.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: 1140px;
  color: #e56a42;
  background-color: #96935d;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
  overflow: visible;
}

.navbar-wrapper {
  height: 40px;
  color: #d4d0fb;
  justify-content: space-between;
  align-items: center;
  display: block;
}

.navbar-brand {
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.navbar-twitter {
  align-items: flex-end;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.nav-menu-wrapper {
  display: flex;
}

.nav-menu-two {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.nav-link {
  color: #f2e1d3;
  letter-spacing: .25px;
  -webkit-text-stroke-color: #f2e1d3;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.nav-link:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link.w--current {
  color: #d4d0fb;
}

.nav-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-dropdown-toggle {
  letter-spacing: .25px;
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
  display: none;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, .75);
}

.nav-dropdown-toggle:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  background-color: #fff;
  border-radius: 12px;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-divider {
  width: 1px;
  height: 22px;
  background-color: #f2e1d3;
  margin-left: 15px;
  margin-right: 15px;
}

.nav-link-accent {
  color: #1a1b1f;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 20px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  display: none;
}

.nav-link-accent:hover {
  color: rgba(26, 27, 31, .75);
}

.menu-button {
  padding: 0;
}

.footer-subscribe {
  background-color: #353654;
  border-bottom: 1px solid #e4ebf3;
  padding: 50px 30px 40px;
  display: none;
  position: relative;
}

.footer-form-two {
  background-color: #f5f7fa;
  margin-bottom: 40px;
  padding: 64px;
}

.footer-form-container-two {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-form-title {
  font-size: 24px;
  line-height: 32px;
}

.footer-form-block-two {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.footer-form-input {
  width: 460px;
  height: 48px;
  border: 1px #000;
  margin-bottom: 0;
  padding: 12px 180px 12px 20px;
}

.footer-form-input::-ms-input-placeholder {
  color: rgba(26, 27, 31, .8);
}

.footer-form-input::placeholder {
  color: rgba(26, 27, 31, .8);
}

.footer-wrapper-three {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-block-three {
  justify-content: flex-start;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
}

.footer-link-three {
  color: #1a1b1f;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
}

.footer-link-three:hover {
  color: rgba(26, 27, 31, .75);
}

.footer-social-block-three {
  justify-content: flex-start;
  align-items: center;
  margin-left: -32px;
  display: flex;
}

.footer-social-link-three {
  margin-left: 32px;
}

.footer-divider-two {
  width: 100%;
  height: 1px;
  background-color: #e4ebf3;
  margin-top: 40px;
  margin-bottom: 40px;
}

.footer-bottom {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-copyright {
  color: #3a4554;
}

.footer-legal-block {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.footer-legal-link {
  color: #3a4554;
  margin-left: 24px;
}

.footer-legal-link:hover {
  color: #3a4554;
}

.grid {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.loader {
  align-items: center;
}

.div-block {
  background-color: rgba(0, 0, 0, 0);
  border: 1px rgba(0, 0, 0, 0);
  border-radius: 10px 10px 0 0;
  align-items: center;
  padding-bottom: 0;
  display: block;
  overflow: visible;
}

.text-block {
  padding-right: 5px;
  text-decoration: none;
}

.artist-image {
  width: 45px;
  height: 45px;
  max-width: 80%;
  border: 1px solid #f2e1d3;
  border-radius: 50%;
  margin: 5px;
  padding: 0;
  line-height: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.artist-more {
  flex-shrink: 0;
  color: #000;
}



.song-cover {
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 1;
  text-align: left;
  object-fit: cover;
  background-color: rgba(164, 198, 199, 0);
  border: 0 rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}


.grid-item-template {
  background-color: #fd0303;
  border: 1px #b9fca8;
  border-radius: 0;
  padding: 0;
  display: none;
  overflow: hidden;
  box-shadow: 0 10px 25px 5px rgba(0, 0, 0, .79);
  max-width: 220px;
}

.artist-name {
  color: #000;
  flex: 1;
  align-self: center;
  font-family: Gotham Medium, sans-serif;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.song-title {
  color: #000;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 5px;
  margin-right: 5px;
  font-family: Gotham MediumItalic, sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  display: block;
}

.div-grid-path-title {
  color: #f2e1d3;
  text-align: center;
  -webkit-text-fill-color: inherit;
  background-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  justify-content: flex-start;
  display: flex;
  overflow: hidden;
}

.song-description {
  z-index: 2;
  width: 100%;
  height: 100%;
  color: #000;
  cursor: auto;
  background-color:#f2e1d3;
  border: 0 #353654;
  border-radius: 0 0% 0%;
  padding-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Gotham XLightItalic, sans-serif;
  font-size: 12px;
  position: static;
  bottom: 0;
  overflow: scroll;
}

.div-block-3 {
  width: 100%;
  height: NaN% ;
  outline-offset: 0px;
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid #353654;
  border-radius: 0;
  outline: 1px solid #000;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, .2);
}

.desc-block {
  z-index: 1;
  width: 100%;
  height: 100%;
  color: #000;
  -webkit-text-fill-color: inherit;
  object-fit: contain;
  background-color: #f2e1d3;
  background-clip: border-box;
  border: 0 #d4d0fb;
  padding-top: 0%;
  transition: opacity .2s, opacity .2s;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0%;
  right: 0;
  overflow: hidden;
}

.div-block-55 {
  background-color: #f2e1d3;
  background-image: none;
  border: 0 #d4d0fb;
  border-radius: 0 0 10px 10px;
  padding-bottom: 0;
  overflow: visible;
  width: 100%;
  max-width: 220px;
}

.div-block-55.div-grid-path-cover {
  outline-offset: 0px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  outline: 1px solid #000;
  overflow: hidden;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .5);
}

.grid-player {
  color: #d4d0fb;
  background-color: #d4d0fb;
  border: 1px solid red;
}

.lottie-animation {
  height: 20px;
}

.heading {
  color: #f2e1d3;
  margin-top: 0;
  font-family: Gotham Bold, sans-serif;
}

.section {
  background-color: #f2e1d3;
  padding-top: 40px;
}

.div-block-6 {
  justify-content: center;
  align-items: flex-end;
  display: none;
}

.div-block-7 {
  flex-direction: column;
  display: flex;
}

.div-song-description {
  width: auto;
  height: 22
  0px;
  background-color: rgba(0, 0, 0, 0);
  background-image: radial-gradient(circle, rgba(150, 147, 93, .15), rgba(229, 106, 66, .05));
  border: 0 #f2e1d3;
  border-radius: 10px;
  flex-direction: row;
  flex: 0 auto;
  align-self: center;
  padding: 20px 10px;
  display: block;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
}

.text-block-2 {
  width: auto;
  height: auto;
  max-width: 500px;
  max-height: 200px;
  color: #000;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid #0b64b3;
  border-radius: 10px;
  flex: 1;
  margin: 0 5px;
  padding: 0;
  font-family: Gotham XLight, sans-serif;
  text-decoration: none;
  overflow: auto;
}

.grid-artist-path {
  width: auto;
  height: auto;
  grid-column-gap: 0px;
  grid-row-gap: 40px;
  background-color: rgba(0, 0, 0, 0);
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: ". . Area";
  grid-auto-flow: row;
}

.grid-item-path {
  width: 100%;
  height: 20px;
  opacity: 0;
  background-color: #6436be;
  display: none;
}

.grid-item-image {
  background-color: #b9fca8;
}

.grid-start {
  height: 45px;
  color: #a4c6c7;
  -webkit-text-stroke-color: #000;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
}

.section-2 {
  height: auto;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 10px;
  margin-bottom: 40px;
}

.utility-page-wrap {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.utility-page-content {
  width: 260px;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.div-block-9 {
  background-color: #13292c;
  position: static;
}

.image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  background-color: rgba(229, 106, 66, .3);
  border: 3px solid #000;
  border-radius: 50%;
  margin: 0 10px;
  padding: 0;
  overflow: hidden;
}

.text-block-3 {
  color: #000;
  text-align: center;
  -webkit-text-stroke-color: #f2e1d3;
  align-self: flex-end;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  font-family: Gotham Black, sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 30px;
}

.html-embed {
  width: 300px;
  height: 300px;
  padding: 0;
  display: none;
}

.div-block-10 {
  margin-top: 20px;
  display: flex;
}

.text-block-4 {
  color: #f2e1d3;
  align-self: center;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Gotham Medium, sans-serif;
}

.log-in-eth-button {
  height: 45px;
  color: #0b64b3;
  text-align: center;
  background-color: #f9d9ed;
  border: 1.5px solid #0b64b3;
  border-radius: 10px;
  align-self: center;
  font-size: 12px;
  text-decoration: none;
}

.log-in-eth-button.button-primary {
  width: 200px;
  color: #f2e1d3;
  background-color: #e56a42;
  border-color: #f2e1d3;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Gotham Medium, sans-serif;
  display: block;
}

.button {
  width: 25px;
  height: 45px;
  color: #a4c6c7;
  -webkit-text-stroke-color: #000;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0);
  background-position: 40% 40%;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  text-decoration: line-through;
}

.button-2 {
  width: 25px;
  height: 45px;
  color: #a4c6c7;
  -webkit-text-stroke-color: #000;
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 0;
  padding-right: 0;
}

.button-3 {
  width: 25px;
  height: 45px;
  color: #a4c6c7;
  -webkit-text-stroke-color: #000;
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 10px;
  padding-left: 0;
  padding-right: 0;
}

.image-2 {
  height: 40px;
  text-align: left;
  flex: 0 auto;
  align-self: center;
  padding-top: 10px;
  padding-bottom: 5px;
  display: inline-block;
}

.grid-svg-path {
  background-color: #f9d9ed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.grid-container {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 20px 20px 0 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  position: static;
}

.mint-date {
  height: 20px;
  color: rgba(229, 106, 66, .7);
  text-align: center;
  -webkit-text-stroke-color: rgba(229, 106, 66, .67);
  background-color: rgba(0, 0, 0, 0);
  font-family: Gotham MediumItalic, sans-serif;
  font-size: 9px;
}

.grid-path {
  height: 20px;
  background-color: #b9fca8;
}

.button-4 {
  width: 45px;
  height: 45px;
  color: #000;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #f2e1d3;
  border-radius: 50%;
  flex: none;
  order: 0;
  align-self: stretch;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0;
  font-family: Gotham Bold, sans-serif;
  font-size: 25px;
  line-height: 45px;
  text-decoration: none;
}

.grid-path-svg {
  display: none;
}

.section-3, .body {
  background-color: #f2e1d3;
}

.section-4 {
  height: auto;
  background-color: #f2e1d3;
}

.section-5 {
  height: 200px;
  background-color: #a4c6c7;
  margin-top: 20px;
  margin-bottom: 20px;
}

.body-2 {
  background-color: #f2e1d3;
}

.div-block-11 {
  margin-bottom: 10px;
}

.div-block-12 {
  height: 2px;
  background-image: linear-gradient(rgba(0, 0, 0, 0), #e56a42 19%, #e56a42 82%, rgba(0, 0, 0, 0) 103%);
}

.div-block-13 {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.grid-path-title {
  color: #000;
  flex: 1;
  align-self: stretch;
  margin-bottom: 10px;
  font-family: Gotham Medium, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.div-artist {
  background-color: #96935d;
  display: flex;
  max-width: 220px;
}

.button-5 {
  height: 50px;
  color: #000;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(242, 225, 211, .5) 29%, #f2e1d3);
  padding-bottom: 10px;
  font-family: Gotham Black, sans-serif;
  font-size: 13px;
  text-decoration: none;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: visible;
}

.div-block-14 {
  width: 2px;
  height: 4px;
  text-align: center;
  background-color: #e56a42;
  border-top-left-radius: 10%;
  border-top-right-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.html-embed-2 {
  width: auto;
  height: auto;
  border-radius: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
  display: none;
  overflow: hidden;
}

.div-block-15 {
  height: 50px;
  text-align: center;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: flex-end;
  display: block;
}

.image-3 {
  height: 40px;
  padding-top: 10px;
  padding-bottom: 5px;
  display: block;
}

.section-6 {
  height: auto;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.html-embed-3 {
  width: auto;
  max-width: 600px;
  border: 2px #000;
  border-radius: 10px;
  margin-bottom: 0;
  margin-left: 0;
  overflow: hidden;
}

.image-4 {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  padding: 60px;
}

.div-block-16 {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: none;
}

.grid-path-pass-img {
  width: 30px;
  height: 30px;
  border: 1px solid #b9fca8;
  border-radius: 50%;
  margin-left: 0;
  margin-right: 10px;
  padding-left: 0;
  padding-right: 0;
}

.container-2 {
  height: auto;
  text-align: center;
  justify-content: center;
  align-items: stretch;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.container-2.artist-content {
  object-fit: fill;
  flex-wrap: nowrap;
  justify-content: center;
}

.div-block-17 {
  width: 300px;
  height: auto;
  background-color: #e56a42;
  border: 0px solid #f2e1d3;
  border-radius: 10px;
  flex: none;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0;
  padding-top: 10px;
  display: block;
}

.div-block-17.line-container {
  outline-offset: 0px;
  background-color: #F5E1D1;
  border-width: 0;
  flex: none;
  padding-bottom: 10px;
}

.text-block-5 {
  max-width: 500px;
  min-width: 300px;
  color: #000;
  text-align: center;
  -webkit-text-stroke-color: #f2e1d3;
  margin-left: 5px;
  font-family: Gotham Medium, sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-decoration: underline;
}

.section-7 {
  height: 210px;
  display: none;
}

.container-3 {
  background-color: #96935d;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}

.div-song-player {
  width: 160px;
  height: 220px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  align-self: flex-start;
  font-size: 250px;
  line-height: 250px;
  position: relative;
}

.artist-collect {
  width: 160px;
  height: 38px;
}

.rounded-button {
  height: 38px;
  width: 160px;
  padding: 0 20px; /* Add some padding on the sides */
  border: 1px solid #F66133; /* Border style */
  border-radius: 10px; /* Rounded corners, half of the button height */
  background-color: #F5E1D1; /* Button background color */
  font-size: 16px; /* Button font size */
  font-family: Gotham Medium, sans-serif;
  color: #F66133;
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Optional: Button hover effect */
.rounded-button:hover {
  background-color: rgba(246, 97, 51, 0.1); /* Change background color on hover */
}


.div-block-19 {
  height: 260px;
  opacity: 0;
  outline-offset: 0px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  outline: 3px #000;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.image-5 {
  height: 100px;
  align-self: center;
}

.container-4 {
  border-radius: 10px;
}

.div-block-56 {
  padding-right: 0;
  position: relative;
  top: 0%;
  bottom: 0;
  left: 0%;
  right: 0;
}

.lottie-animation-2 {
  flex: 0 auto;
  justify-content: flex-end;
  align-self: center;
  padding-left: 1px;
  padding-right: 1px;
  display: flex;
}

.image-6 {
  width: 200px;
  height: 200px;
  align-self: flex-end;
  margin-bottom: 50px;
  margin-left: 20px;
}

.container-5 {
  height: 100vh;
}

.text-block-6 {
  text-align: center;
  display: block;
}

.lottie-animation-3 {
  height: 150px;
}

.image-7 {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border: 1px solid rgba(242, 225, 211, .55);
  border-radius: 50%;
  padding-top: 0;
  padding-bottom: 0;
  display: inline-block;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 50%;
  right: 50%;
  overflow: hidden;
  transform: translate(-45px);
  border-width: 0;
  border-color: #000;
  border-radius: 50%;
  outline: 1px #000;
  flex: none;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .5);
}

.icon, .div-block-57 {
  display: none;
}

.div-block-58 {
  align-self: flex-end;
}

.div-audio-player {
  width: 160px;
  height: auto;
  align-self: center;
  margin-left: 40px;
  display: block;
}

.image-8 {
  z-index: 100;
  width: 160px;
  height: 160px;
  max-width: none;
  background-color: #96935d;
  border: 1px solid #000;
  border-radius: 5px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.html-embed-4 {
  width: auto;
  height: auto;
  flex: 1;
  align-self: stretch;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.button-6 {
  display: none;
}

.image-9 {
  z-index: 99;
  width: 160px;
  height: 160px;
  max-width: none;
  font-size: 200px;
  line-height: 200px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.image-10 {
  object-fit: fill;
}

.div-block-59 {
  width: 150px;
  height: 40px;
  object-fit: contain;
  flex: 0 auto;
  align-self: flex-end;
  margin-bottom: 10px;
  margin-right: 20px;
  display: block;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.image-11 {
  width: 150px;
  height: 40px;
  object-fit: contain;
  margin-bottom: 0;
  margin-right: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.list-item {
  align-self: stretch;
  margin-right: 10px;
}

.list-item-2 {
  align-self: stretch;
}

.list-item-3 {
  color: #f2e1d3;
  margin-right: 10px;
}

.list-item-4, .list-item-5, .list-item-6 {
  color: #f2e1d3;
}

.list-item-7 {
  color: #f2e1d3;
  align-self: center;
}

.list-item-8 {
  outline-offset: 0px;
  color: #f2e1d3;
  border: 1.5px solid #f2e1d3;
  border-radius: 10px;
  outline: 1px #f2e1d3;
  margin-top: 0;
  padding: 5px 10px;
  font-family: Gotham Medium, sans-serif;
}

.list-item-9 {
  color: #f2e1d3;
  align-self: center;
  margin-left: 10px;
}

.lottie-animation-4 {
  width: 110px;
  height: 110px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 50%;
  right: 50%;
  transform: translate(-55px, -15px);
}

.div-block-60 {
  width: 100%;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.button-7 {
  z-index: 103;
  width: 45px;
  height: 45px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: auto;
}

.button-7.play {
  background-color: rgba(0, 0, 0, 0);
}

.html-embed-5 {
  z-index: 103;
  width: 80px;
  height: 80px;
  background-color: #b9fca8;
  display: none;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.button-8 {
  z-index: 105;
  width: 80px;
  height: 80px;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.div-block-61 {
  height: 20px;
  line-height: 45px;
  display: none;
  position: relative;
}

.lottie-animation-5 {
  z-index: 110;
  width: 100%;
  height: 40px;
  display: none;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.grid-pass-feed {
  grid-template: ". Area"
  / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  display: block;
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 728px;
  }

  .hero-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: -40px;
    display: flex;
  }

  .hero-split {
    max-width: 100%;
    align-self: center;
    align-items: stretch;
    margin-bottom: 20px;
  }

  .shadow-two {
    width: 60vw;
    flex: 0 auto;
    align-self: center;
    padding-left: 0;
    padding-right: 0;
  }

  .nav-menu-wrapper {
    background-color: rgba(0, 0, 0, 0);
  }

  .nav-menu-two {
    background-color: #fff;
    border-radius: 50px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .menu-button {
    padding: 12px;
  }

  .menu-button.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .footer-form-two {
    padding: 40px;
  }

  .footer-form-title {
    margin-right: 15px;
  }

  .footer-form-input {
    width: 400px;
    padding-right: 180px;
  }

  .footer-wrapper-three {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-social-block-three {
    margin-top: 40px;
  }

  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid-item-template {
    display: none;
  }

  .div-song-description {
    outline-offset: 0px;
    outline: 3px #0b64b3;
  }

  .text-block-2 {
    outline-offset: 0px;
    border-color: #e56a42;
    outline: 3px #000;
  }

  .grid-artist-path {
    border: 0 #000;
  }

  .div-block-10 {
    justify-content: flex-start;
    margin-top: 40px;
  }

  .light-text-field {
    width: 90%;
  }
  
  .log-in-eth-button {
    width: 90%;
  }
  
  .log-in-button {
    width: 90%;
  }

  .log- {
    width: 90%;
  }

  .html-embed-2 {
    display: none;
  }

  .html-embed-3 {
    max-width: none;
  }

  .image-4 {
    width: 300px;
    height: 300px;
    align-self: flex-end;
    padding: 10px;
  }

  .div-block-17.line-container {
    outline-offset: 0px;
    border-color: #a4c6c7;
    outline: 0px #a4c6c7;
  }

  .div-song-player {
    width: 160px;
    height: 220px;
  }

  .div-block-58 {
    align-self: flex-end;
  }

  .div-audio-player {
    width: auto;
    height: auto;
    align-self: flex-start;
  }

  .image-8 {
    width: 160px;
    height: 160px;
  }
}

@media screen and (max-width: 767px) {
  .hero-heading-left {
    padding: 60px 15px;
  }

  .hero-split {
    align-self: center;
  }

  .shadow-two {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-logo-left-container {
    max-width: 100%;
  }

  .navbar-brand {
    padding-left: 0;
  }

  .navbar-twitter {
    padding-right: 0;
    margin-right: 0;
  }
  .nav-menu-two {
    border-radius: 20px;
    flex-direction: column;
    padding-bottom: 30px;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .nav-divider {
    width: 200px;
    height: 1px;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nav-link-accent {
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .footer-subscribe {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer-form-container-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-form-title {
    margin-right: 0;
  }

  .footer-form-block-two {
    width: 100%;
    margin-top: 20px;
  }

  .footer-form-input {
    width: 100%;
  }

  .footer-block-three {
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-link-three {
    margin-bottom: 10px;
  }

  .footer-social-block-three {
    margin-top: 10px;
  }

  .footer-bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-copyright {
    font-size: 14px;
    line-height: 20px;
  }

  .footer-legal-block {
    margin-top: 15px;
    margin-left: -24px;
  }

  .footer-legal-link {
    font-size: 14px;
    line-height: 20px;
  }

  .grid {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 0 auto;
  }

  .div-song-description {
    display: block;
  }

  
  .grid-artist-path {
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 5px;
    margin-right: 5px;
  }

  .div-block-10 {
    justify-content: center;
  }

  .section-6 {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .html-embed-3 {
    width: 100vw;
    margin-left: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .image-4 {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 479px) {
  .div-artist {
    max-width: 100%;   
  }
  .div-block-55 {
    max-width: 100%;   
  }

  .container {
    max-width: none;
  }

  .hero-split {
    align-items: center;
    display: block;
  }

  .button-primary.footer-form-button {
    position: relative;
    right: 0;
  }

  .shadow-two {
    width: auto;
    flex: 0 auto;
    order: 0;
    align-self: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  .nav-menu-two {
    flex-direction: column;
  }

  .footer-form-two {
    padding: 20px;
  }

  .footer-form-block-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-form-input {
    margin-bottom: 10px;
    padding-right: 20px;
  }

  .footer-bottom {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .grid {
    grid-template-columns: 1fr;
    margin-left: 5px;
    margin-right: 5px;
    width: 100%;
    margin: 0 auto;
  }

  .grid-item-template {
    width: 90%;
    margin: 0 auto;
  }

  .div-song-description {
    display: block;
    overflow: hidden;
  }

  .grid-artist-path {
    grid-row-gap: 40px;
    grid-template-columns: 1fr 1fr;
    margin-left: 5px;
    margin-right: 5px;
  }

  .image {
    width: 60px;
    height: 60px;
  }

  .text-block-3 {
    font-size: 16px;
  }

  .div-block-10 {
    align-self: flex-start;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .text-block-4 {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .html-embed-2 {
    margin-left: auto;
    margin-right: auto;
  }

  .section-6 {
    display: block;
  }

  .html-embed-3 {
    width: 300px;
    height: 500px;
    max-width: 0;
    min-width: auto;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .image-4 {
    width: 300px;
    height: 300px;
    padding: 20px;
  }

  .div-song-player {
    width: 160px;
    height: 220px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .div-block-19 {
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
    display: block;
  }

  .image-6 {
    margin-bottom: 0;
  }

  .div-block-57 {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .div-block-58 {
    justify-content: flex-end;
    display: flex;
  }

  .div-audio-player {
    height: auto;
    margin: 0 auto;
    align-self: center;
  }

  .image-8 {
    width: 160px;
    height: 160px;
  }
}


@font-face {
  font-family: 'Gotham BoldItalic';
  src: url('../fonts/Gotham-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham MediumItalic';
  src: url('../fonts/Gotham-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Black';
  src: url('../fonts/Gotham-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('../fonts/Gotham-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Light';
  src: url('../fonts/Gotham-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Medium';
  src: url('../fonts/Gotham-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham ThinItalic';
  src: url('../fonts/Gotham-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham UltraItalic';
  src: url('../fonts/Gotham-UltraItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham XLight';
  src: url('../fonts/Gotham-XLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham XLightItalic';
  src: url('../fonts/Gotham-XLightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gotham BookItalic';
  src: url('../fonts/Gotham-BookItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}